<div class="order-details">
    <div class="padding-right">
        <div class="label-box">
            <div>
                <div *ngIf="this.orderHistoryService.labours?.length > 0" class="d-flex align-items-center">
                    <app-toggle-switch [size]="'BIG'"
                                       [textLeft]="'ORDER.SHOW_WORKING_TIMES' | translate"
                                       (onChange)="showWorkingTimes = !showWorkingTimes"
                                       [isChecked]="showWorkingTimes"></app-toggle-switch>
                </div>
            </div>
            <div>
                <button class="base-btn float-right" *ngIf="!userService.sale"
                        routerLink="orderhistory/order-print">
                    <i class="fa fa-print"></i>
                    <span>{{ 'PRINT_VIEW.PRINT' | translate }}</span>
                </button>
                <button class="base-btn float-right" *ngIf="userService.sale"
                        (click)="pdfService.updateDmsData(); cartService.currentCart = orderHistoryService.cartData; cartService.backLink = router.url"
                        routerLink="orderhistory/offer">
                    <i class="fa fa-file-pdf-o"></i>
                    <span>{{ 'PRINT_VIEW.PRINT_OFFER' | translate }}</span>
                </button>
            </div>
        </div>
    </div>

    <div class="padding-right">
        <div class="label-box" *ngIf="orderHistoryService?.cartData?.reference">
            <label>{{ 'CART.REFERENCE' | translate }}</label>
            <div>{{ orderHistoryService?.cartData?.reference }}</div>
        </div>
        <div class="label-box" *ngIf="orderHistoryService?.cartData?.platformOrderId">
            <label>{{ 'ORDER.ORDERNUMBER' | translate }}</label>
            <div>{{ orderHistoryService?.cartData?.platformOrderId }}</div>
        </div>
        <div class="label-box" *ngIf="orderHistoryService?.cartData?.loginName">
            <label>{{ 'SUPPLIERS.USERNAME' | translate }}</label>
            <div>{{ orderHistoryService?.cartData?.loginName }} <span *ngIf="userService.allow('GLOBAL-ADMIN')">(ID: {{ orderHistoryService?.cartData?.loginId }})</span></div>
        </div>
    </div>

    <div class="padding-right">
        <div class="label-box">
            <label>{{'GENERAL.PRICE.SUM_NET' | translate }}</label>
            <div class="text-right">{{userService.sale ? orderHistoryService?.cartData?.cartCostDTO?.sellOutNetTotalFormatted :
                orderHistoryService?.cartData?.cartCostDTO?.sellInNetTotalFormatted}}</div>
        </div>
        <div class="label-box">
            <label>{{'GENERAL.PRICE.VAT' | translate: {vat: orderHistoryService?.cartData?.cartCostDTO?.vat} }}</label>
            <div class="text-right">{{userService.sale ? orderHistoryService?.cartData?.cartCostDTO?.sellOutVatTotalTotalFormatted :
                orderHistoryService?.cartData?.cartCostDTO?.sellInVatTotalFormatted}}</div>
        </div>
        <div class="label-box" *ngIf="orderHistoryService?.cartData?.cartCostDTO?.deliveryCostsTotal >0">
            <label>{{'GENERAL.PRICE.SHIPMENT_COST' | translate}}</label>
            <div class="text-right">{{ orderHistoryService?.cartData?.cartCostDTO?.deliveryCostsTotalFormatted}}</div>
        </div>
        <div class="label-box" *ngIf="!orderHistoryService?.cartData?.cartCostDTO?.deliveryCostsTotal || orderHistoryService?.cartData?.cartCostDTO?.deliveryCostsTotal === 0">
            <h3>{{'GENERAL.PRICE.SUM' | translate }}</h3>
            <h3 class="text-right">{{userService.sale ? orderHistoryService?.cartData?.cartCostDTO?.sellOutGrossTotalFormatted :
                orderHistoryService?.cartData?.cartCostDTO?.sellInGrossTotalFormatted}}</h3>
        </div>
        <div class="label-box" *ngIf="orderHistoryService?.cartData?.cartCostDTO?.deliveryCostsTotal || orderHistoryService?.cartData?.cartCostDTO?.deliveryCostsTotal > 0">
            <h3>{{'GENERAL.PRICE.SUM' | translate }}</h3>
            <h3 class="text-right">{{userService.sale ? orderHistoryService?.cartData?.cartCostDTO?.sellOutGrossTotalFormatted :
                orderHistoryService?.cartData?.cartCostDTO?.sellInGrossTotalWithDeliveryCostsFormatted}}</h3>
        </div>
    </div>

    <div class="card supplier-card" *ngFor="let position of this.orderHistoryService.positions">
        <div class="header">
            <div class="row">
                <div class="col-2">
                    <img class="img-fluid"
                         [supplier]="position.supplier"
                         [logo-color]="true"
                         title="{{position.supplierName}}">
                </div>
                <div class="col">
                    {{position.supplierName}}
                </div>
            </div>
        </div>
        <div class="content">
            <div *ngIf="position.brandName" class="label-box">
                <label>{{'ORDER.BRAND_NAME' | translate}}</label>
                {{position.brandName}}
            </div>
            <div *ngIf="position.dealerArticleNumber" class="label-box">
                <label>{{'ORDER.DEALER_ARTICLE_NUMBER' | translate}}</label>
                {{position.dealerArticleNumber}}
            </div>
            <div *ngIf="position.articleId" class="label-box">
                <label>{{'ORDER.ARTICLE_ID' | translate}}</label>
                {{position.articleId}}
            </div>
            <div *ngIf="position.description" class="label-box">
                <label>{{'ORDER.DESCRIPTION' | translate}}</label>
                {{position.description}}
            </div>
            <div *ngIf="position.quantity" class="label-box">
                <label>{{'ORDER.QUANTITY' | translate}}</label>
                {{position.quantity}}
            </div>
            <div class="label-box">
                <label>{{'ORDER.ORDERED' | translate}}</label>
                <i *ngIf="position.submittedWithOrder" class="fa fa-check"></i>
                <i *ngIf="!position.submittedWithOrder" class="fa fa-close"></i>
            </div>
            <hr/>
            <div class="label-box">
                <label>{{ userService.sale ? ('GENERAL.PRICE.SINGLE' | translate) : ('GENERAL.PRICE.SINGLE_NET' | translate) }}</label>
                <div class="text-right">{{userService.sale ? position.sellOutNetPerPieceFormatted : position.sellInNetPerPieceFormatted}}</div>
            </div>
            <div class="label-box">
                <label>{{ userService.sale ? ('GENERAL.PRICE.TOTAL' | translate) : ('GENERAL.PRICE.TOTAL_NET' | translate) }}</label>
                <div class="text-right">{{userService.sale ? position.sellOutNetTotalFormatted : position.sellInNetTotalFormatted}}</div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="showWorkingTimes">
    <div class="card supplier-card" *ngFor="let position of this.orderHistoryService.labours">
        <div class="header">
            <div class="row">
                <div class="col-2">
                    <i class="fa fa-clock-o fa-lg"></i>
                </div>
                <div class="col">
                    {{'ORDER.WORKING_TIME' | translate}}
                </div>
            </div>
        </div>
        <div class="content">
            <div *ngIf="position.brandName" class="label-box">
                <label>{{'ORDER.BRAND_NAME' | translate}}</label>
                {{position.brandName}}
            </div>
            <div *ngIf="position.dealerArticleNumber" class="label-box">
                <label>{{'ORDER.DEALER_ARTICLE_NUMBER' | translate}}</label>
                {{position.dealerArticleNumber}}
            </div>
            <div *ngIf="position.articleId" class="label-box">
                <label>{{'ORDER.ARTICLE_ID' | translate}}</label>
                {{position.articleId}}
            </div>
            <div *ngIf="position.description" class="label-box">
                <label>{{'ORDER.DESCRIPTION' | translate}}</label>
                {{position.description}}
            </div>
            <div *ngIf="position.quantity" class="label-box">
                <label>{{'ORDER.QUANTITY' | translate}}</label>
                {{position.quantity}}
            </div>
            <div class="label-box">
                <label>{{'ORDER.ORDERED' | translate}}</label>
                <i *ngIf="position.submittedWithOrder" class="fa fa-check"></i>
                <i *ngIf="!position.submittedWithOrder" class="fa fa-close"></i>
            </div>
            <hr/>
            <div class="label-box">
                <label>{{ userService.sale ? ('GENERAL.PRICE.SINGLE' | translate) : ('GENERAL.PRICE.SINGLE_NET' | translate) }}</label>
                <div class="text-right">{{userService.sale ? position.sellOutNetPerPieceFormatted : position.sellInNetPerPieceFormatted}}</div>
            </div>
            <div class="label-box">
                <label>{{ userService.sale ? ('GENERAL.PRICE.TOTAL' | translate) : ('GENERAL.PRICE.TOTAL_NET' | translate) }}</label>
                <div class="text-right">{{userService.sale ? position.sellOutNetTotalFormatted : position.sellInNetTotalFormatted}}</div>
            </div>
        </div>
    </div>
    </ng-container>

    <div class="row">
        <div class="col">
            <app-collapsible-panel [panelTitle]="supplier.key" *ngFor="let supplier of orderHistoryService?.cartData?.externalIds">
                <div class="row">
                    <div class="col-5">
                        <span>{{ 'CART.ORDER_NUMBER' | translate }}</span>
                    </div>
                    <div class="col-7">
                        <span>{{ supplier.value }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5">
                        <span>{{ 'CART.ACQUISITION_TYPE' | translate }}</span>
                    </div>
                    <div class="col-7">
                        <span>{{ 'CART.ACQUISITION_TYPES.' + orderHistoryService.cartData.selectedAcquisitionTypes[supplier.key] | translate }}</span>
                    </div>
                </div>
            </app-collapsible-panel>
        </div>
    </div>


</div>

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AdminService} from '../../club/services/admin.service';
import {UserService} from '../../../shared/services/user/user.service';
import {IDateModel} from '../../../shared/interfaces/dateModel';
import {IAdminFirm} from '../../../shared/interfaces/adminFirm';
import {ErrorHandlerService} from '../../../shared/components/error-handler/services/error-handler.service';
import {HelperService} from '../../../shared/services/helper.service';
import {Router} from '@angular/router';
import {UntypedFormGroup} from '@angular/forms';
import {IRights} from "../../../shared/interfaces/IRights";
import {PrivilegesEnum, RolesEnum} from "../../../shared/privilegesLicencesRoles.enum";

@Component({
    selector: 'app-firm-data',
    templateUrl: './firm-data.component.html'
})
export class FirmDataComponent implements OnInit, OnDestroy {

    @Input() public firmData: any;
    @Input() public isAdmin: boolean;
    @Input() public isCreate: boolean | false;
    @Output() public _save: EventEmitter<any> = new EventEmitter();
    public date: Date;
    public dateModel: IDateModel;
    public states: any;
    public roles: string[];
    private clubs: {};
    private currentCountryCode: string = '';
    public zipPattern: string = '';
    public rights: IRights = <IRights>{};
    public readonly privilegeIsAdminPrivilegDefaultsetted: string[] = [PrivilegesEnum.FIRM_STATISTIC, PrivilegesEnum.USER_MANAGEMENT, PrivilegesEnum.ORDER_HISTORY, PrivilegesEnum.SUPPLIER_MANAGEMENT, PrivilegesEnum.SETTINGS_MANAGEMENT, PrivilegesEnum.ASSORTMENT_PRIORITISATION];

    firmNameForm = new UntypedFormGroup({});
    //
    firmAddressForm = new UntypedFormGroup({});

    constructor(public userService: UserService,
                public errorHandlerService: ErrorHandlerService,
                public adminService: AdminService,
                private router: Router,
                public helperService: HelperService) {
        this.dateModel = {year: 0, month: 0, day: 0};

        if (this.firmData && !this.firmData.name) {
            this.firmData.name = 'Neue Firma';
        }
    }

    ngOnInit() {
        this.errorHandlerService.hasError = false;

        if (this.userService.allow('GLOBAL-ADMIN')) {
            this.adminService.getStatesRestForCLub(this.adminService.getClubId()).subscribe(
                (response) => {
                    this.states = response;
                    if (this.isCreate) {
                        let state = this.states.find(state => state.name === 'GERMANY');
                        if (state) {
                            this.firmData.address.country = state;
                        } else {
                            this.firmData.address.country = this.states[0];
                        }
                    }
                    this.currentCountryCode = this.firmData.address.country.iso31661Alpha2;
                    this.checkZipForCountry(this.currentCountryCode);
                }
            );
        } else {
            this.adminService.getStatesRest().subscribe(
                (response) => {
                    this.states = response;
                    if (this.isCreate) {
                        let state = this.states.find(state => state.name === 'GERMANY');
                        if (state) {
                            this.firmData.address.country = state;
                        } else {
                            this.firmData.address.country = this.states[0];
                        }
                    }
                    this.currentCountryCode = this.firmData.address.country.iso31661Alpha2;
                    this.checkZipForCountry(this.currentCountryCode);
                }
            );
        }


        if (this.firmData === undefined) {
            this.firmData = {
                active: true,
                address: {
                    address1: '',
                    address2: '',
                    city: '',
                    id: 0,
                    name1: '',
                    name2: '',
                    country: {},
                    zip: ''
                },
                clubId: 0,
                email: '',
                expirationDate: undefined,
                externalId: '',
                fax: '',
                hourlyWageRate: 0,
                hourlyWageRateChassis: 0,
                hourlyWageRateElectronic: 0,
                hourlyWageRateMechanic: 0,
                locale: 'de_DE',
                name: '',
                phone: '',
                uuid: '',
                valid: true,
                vat: 0,
                possibleRoles: [],
                possiblePrivileges: [],
                licence: ''
            };
            if (this.userService.allow('CLUB-ADMIN')) {
                this.adminService.getPossibleRightsRest().subscribe(
                    (rights) => {
                        this.rights = rights;
                        rights.privileges = this.sortPrivileges(rights.privileges);
                        this.rights = rights;
                        if (this.userService.accountData.clubId && !this.adminService.club) {
                            this.firmData.clubId = this.userService.accountData.clubId;
                        } else if (this.adminService.club) {
                            this.firmData.clubId = this.adminService.club.id;
                        }
                    });
            }
            if (this.adminService.clubs === undefined && this.userService.allow('GLOBAL-ADMIN')) {
                this.adminService.getClubsRest().subscribe(
                    (success) => {
                        this.adminService.clubs = success;
                    }
                );
            }
        } else {
            if (this.userService.allow('CLUB-ADMIN')) {
                this.adminService.getPossibleRightsRest().subscribe((rights) => {
                    this.rights = rights;
                    rights.privileges = this.sortPrivileges(rights.privileges);
                    this.rights = rights;
                });
            }
        }

        this.clubs = this.adminService.clubs;

    }

    public checkZipForCountry(country: string): void {

        switch (country) {
            // Romania: Match exactly 6 digits
            case 'RO':
                this.zipPattern = '^\\d{6}$';
                break;
            case 'DE':
            case 'ES':
            case 'IT':
            case 'FR':
                this.zipPattern = '^\\d{5}$';
                break;
            // Portugal: Match 4 digits, - , and 3 digits
            case 'PT':
                this.zipPattern = '^\\d{4}-\\d{3}$';
                break;
            // Austria, Belgium, Swiss, Bulgaria, Hungary: Match exactly 4 digits
            case 'AT':
            case 'BE':
            case 'CH':
            case 'BG':
            case 'HU':
                this.zipPattern = '^\\d{4}$';
                break;
            // United Kingdom: Match UK postal codes
            // Format 1: One or two letters followed by one or two numbers, optionally followed by a space and then one number followed by two letters.
            //Format 2: One or two letters followed by one number, optionally followed by another letter or number, and an optional space, then one number followed by two letters.
            case 'GB':
                this.zipPattern = '^([A-Z][0-9]{1,2} ?[0-9][A-Z]{2}|[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2})$';
                break;
            // Poland: Match 2 digits, optional hyphen or space, and 3 digits
            case 'PL':
                this.zipPattern = '^\\d{2}[- ]{0,1}\\d{3}$';
                break;
            // Sweden: Match 3 digits, optional space, and 2 digits
            case 'SE':
                this.zipPattern = '^\\d{3}\\s*\\d{2}$';
                break;
            // Greece, Czech Republic, Slovakia: Match 3 digits, optional space, and 2 digits
            case 'CZ':
            case 'SK':
            case 'GR':
                this.zipPattern = '^\\d{3}\\s?\\d{2}$';
                break;
            // Netherlands: Match 4 digits, optional space, and 2 letters
            case 'NL':
                this.zipPattern = '^\\d{4}\\s{0,1}[A-Za-z]{2}$';
                break;
            // Default case: Match any characters (at least one)
            default:
                this.zipPattern = '/^.+/';
                break;
        }
    }

    public createDate() {
        this.date = new Date();
        this.date.setMonth(this.date.getMonth() + 1);
        if (this.date.getMonth() === 0) {
            this.date.setMonth(1);
        }
        this.dateModel.day = this.date.getDate();
        this.dateModel.month = this.date.getMonth();
        this.dateModel.year = this.date.getFullYear();
        this.firmData.expirationDate = this.dateModel;
    }

    public toggleSelection(type: string, newValue: string) {
        switch (type) {
            case 'ROLE':
                if (this.firmData.possibleRoles.find((role: string) => role === newValue)) {
                    this.firmData.possibleRoles = this.firmData.possibleRoles.filter((roles: string) => roles !== newValue);
                } else {
                    this.firmData.possibleRoles.push(newValue);
                }
                if (this.firmData.possibleRoles.some((role: string) =>
                    role === RolesEnum.ROLE_FIRM_ADMIN || role === RolesEnum.ROLE_ADMIN)) {
                    this.firmData.possiblePrivileges = this.rights.privileges;
                } else {
                    this.firmData.possiblePrivileges = [];
                }
                break;
            case 'LICENCE':
                this.firmData.licence = newValue;
                break;
            case 'PRIVILEGE':
                if (this.firmData.possiblePrivileges.find((privilege: string) => privilege === newValue)) {
                    this.firmData.possiblePrivileges = this.firmData.possiblePrivileges.filter((privilege: string) => privilege !== newValue);
                } else {
                    this.firmData.possiblePrivileges.push(newValue);
                }
                break;
        }
    }

    public resetDate() {
        this.dateModel = {year: 0, month: 0, day: 0};
        this.firmData.expirationDate = undefined;
    }

    public setDate(newDate: string) {
        if (newDate.length > 0) {
            this.date = new Date();
            this.date.setDate(Number.parseInt(newDate.slice(0, newDate.indexOf('.'))));
            this.date.setMonth(Number.parseInt(newDate.slice(newDate.indexOf('.') + 1, newDate.lastIndexOf('.'))) - 1);
            this.date.setFullYear(Number.parseInt(newDate.slice(newDate.lastIndexOf('.') + 1)), this.date.getMonth(), this.date.getDate());
            this.firmData.expirationDate = this.date;
        }
    }

    public checkId(item1: any, item2: any) {
        return item1.id === item2.id;
    }

    public sortPrivileges(privileges: string[]): string[] {
        const isAdminPrivileg = (privilege: string) => this.privilegeIsAdminPrivilegDefaultsetted.includes(privilege);
        return privileges.sort((a, b) => {
            if (isAdminPrivileg(a) && !isAdminPrivileg(b)) {
                return 1;
            }
            if (!isAdminPrivileg(a) && isAdminPrivileg(b)) {
                return -1;
            }
            return 0;
        });
    }

    public deletFirm(firm: IAdminFirm, _delete: boolean) {
        if (!_delete) {
            firm.delete = true;
        } else {
            this.adminService.deleteFirm(firm.firmId).subscribe(
                (success) => {
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_ENTRY', 'success');
                    this.adminService.step[0] = [];
                    this.adminService.step[1] = '';
                    this.adminService.step[2] = false;
                    this.adminService.selectedFirm = undefined;
                    this.router.navigate(['admin/']);
                },
                (error) => {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_ENTRY', 'error');
                }
            )
        }
    }

    public saveFirm($event?: any) {

        if (this.isCreate) {
            if (this.adminService.club && this.adminService.club.clubId) {
                this.firmData.clubId = this.adminService.club.clubId;
            } else {
                this.firmData.clubId = this.userService.accountData.clubId;
            }
        }

        this.firmData.skipCheck = false;

        if ($event && $event.errorCode === 409 && $event.btnString === 'OK') {
            this.firmData.skipCheck = true;
            this._save.emit(this.firmData);
        } else {
            if (!$event) {
                this._save.emit(this.firmData);
            }
        }
    }

    ngOnDestroy() {
        this.adminService.validationErrorFirm$.next('');
    }

}
